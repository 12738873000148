import * as React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function Image({
  allImages, filename, alt, ...props
}) {
  const sharp = allImages.find((item) => item.node.parent.relativePath === filename);

  if (!sharp) {
    throw Error(`Not found image ${filename}`);
  }

  return <GatsbyImage image={sharp.node.gatsbyImageData} alt={alt} {...props} />;
}
