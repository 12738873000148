import { getCurrentLangKey } from 'ptz-i18n';
import 'intl';
import { globalHistory } from '@reach/router';
import NL2BR from 'react-nl2br';
import Languages from '../languages';

export default function I18NField({ obj, property, nl2br }) {
  const { langs, defaultLangKey } = Languages;
  const langKey = getCurrentLangKey(langs, defaultLangKey, globalHistory.location.pathname);
  const i18nProperty = langKey === defaultLangKey ? property : `${property}_${langKey}`;

  const value = !!obj[i18nProperty] && !!obj[i18nProperty].trim() ? obj[i18nProperty] : obj[property];

  if (nl2br) {
    return NL2BR(value);
  }
  return value;
}
