// extracted by mini-css-extract-plugin
export var back = "category-module--back--7eda3";
export var itemDetails = "category-module--item-details--3eac3";
export var itemImage = "category-module--item-image--1fdb8";
export var itemLine = "category-module--item-line--6bae1";
export var itemPrice = "category-module--item-price--2741c";
export var itemWithImage = "category-module--item-with-image--62923";
export var itemsList = "category-module--items-list--59332";
export var itemsTitle = "category-module--items-title--c1178";
export var menuBgCocktails = "category-module--menu-bg-cocktails--8dd1b";
export var menuBgHookah = "category-module--menu-bg-hookah--234e3";
export var modalClose = "category-module--modal-close--b7e84";
export var modalImage = "category-module--modal-image--e01d2";
export var modalTitle = "category-module--modal-title--82f1e";
export var wrapper = "category-module--wrapper--fffe6";