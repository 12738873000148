import { graphql } from 'gatsby';
import * as React from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import Modal from 'react-modal';
import classnames from 'classnames';
import Helmet from 'react-helmet';
import Layout from '../layouts';
import Wrapper from '../components/Wrapper';
import MenuItemThumbnail from '../components/MenuItemThumbnail';
import MenuItemImage from '../components/MenuItemImage';

import messagesEN from '../data/messages/en';
import messagesRU from '../data/messages/ru';
import messagesES from '../data/messages/es';
import Button from '../components/Button';
import * as CSS from './category.module.css';
import * as GlobalCSS from '../styles.module.css';
import I18NField from '../components/I18NField';

import 'intl/locale-data/jsonp/ru';
import 'intl/locale-data/jsonp/es';
import 'intl/locale-data/jsonp/en';

const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },

  content: {
    // backgroundColor: 'rgba(0, 0, 0, 0.75)',
    backgroundColor: 'transparent',
    border: '',
    maxWidth: '600px',
    padding: '0',
    color: '#FDF5DB',
    textAlign: 'center',
    borderRadius: '3px',
    overflow: 'auto',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

function Left() {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 10">
      <path d="M6 9.106 5.09 10 0 5 5.09 0 6 .894 1.82 5 6 9.106Z" fill="#F4F2EA" />
    </svg>
  );
}

function CategoryItemsList({ items, openPhoto }) {
  return (
    <div className={CSS.itemsList}>
      {
                items.map((item) => (
                  <div className={CSS.itemLine} key={item.title}>
                    <div className={CSS.itemImage}>
                      {item.image ? (
                        <span onClick={() => openPhoto(item)}>
                          <MenuItemThumbnail
                            filename={`category-items/no-background/${item.image}.png`}
                            alt={item.title}
                          />
                        </span>
                      ) : null}
                    </div>
                    <div
                      className={classnames(CSS.itemDetails, { [CSS.itemWithImage]: !!item.image })}
                      onClick={item.image ? (() => openPhoto(item)) : null}
                    >
                      <div><I18NField obj={item} property="title" /></div>
                      <small><I18NField obj={item} property="details" nl2br /></small>
                    </div>
                    <div className={CSS.itemPrice}>
                      €
                      {item.price}
                    </div>
                  </div>
                ))
            }
    </div>
  );
}

function Page({ data: { category }, pageContext: { language } }) {
  const [photoIsOpen, setPhotoIsOpen] = React.useState(false);
  const [itemModal, setItemModal] = React.useState(null);

  function openPhoto(item) {
    setItemModal(item);
    setPhotoIsOpen(true);
  }

  function closePhoto() {
    setItemModal(null);
    setPhotoIsOpen(false);
  }

  const messages = {
    ru: messagesRU,
    es: messagesES,
    en: messagesEN,
  }[language];

  return (
    <IntlProvider locale={language} messages={messages}>
      <FormattedMessage id="menuTitle">
        {(msg) => (
          <Helmet
            title={`${
              I18NField({ obj: category, property: 'category' })} / ${msg} / 
              Hookah Base - Shisha Lounge & Cocktails Bar`}
          />
        )}
      </FormattedMessage>

      <Layout className={CSS[category.className]}>
        {itemModal ? (
          <Modal
            ariaHideApp={false}
            isOpen={photoIsOpen}
            onRequestClose={closePhoto}
            style={modalStyles}
          >
            <div className={CSS.modalClose} onClick={closePhoto}>
              <div className={classnames(CSS.modalTitle, GlobalCSS.textLogo, GlobalCSS.textLogoYellow)}>
                <I18NField obj={itemModal} property="title" />
              </div>
            </div>

            <div onClick={closePhoto}>
              <MenuItemImage
                filename={`category-items/${itemModal.image}.jpg`}
                alt={itemModal.title}
                className={CSS.modalImage}
              />
            </div>
          </Modal>
        ) : null}

        <Wrapper>
          <div className={CSS.wrapper}>
            <h1 className={GlobalCSS.textLogo}>
              <Button to="/menu/" type="link" className={CSS.back}><Left /></Button>
              <FormattedMessage id="menuTitle" />
            </h1>
            <h2><I18NField obj={category} property="category" /></h2>

            {!category.subcategories && (
            <CategoryItemsList items={category.items} openPhoto={openPhoto} />
            )}

            {!!category.subcategories && (
            <>
              {category.subcategories.map((subcategory) => (
                <div key={subcategory.category}>
                  <div className={CSS.itemsTitle}>
                    <I18NField
                      obj={subcategory}
                      property="category"
                    />
                  </div>
                  <CategoryItemsList items={subcategory.items} openPhoto={openPhoto} />
                </div>
              ))}
            </>
            )}
          </div>
        </Wrapper>
      </Layout>
    </IntlProvider>
  );
}

export default Page;

export const query = graphql`
    query($slug: String!)
    {
        category(slug:{eq: $slug})
        {
            ... on Category
            {
                category
                category_en
                category_ru
                slug
                className
                
                items {
                    title
                    title_en
                    title_ru
                    price
                    details
                    details_en
                    details_ru
                    image
                }
                
                subcategories {
                    category
                    category_en
                    category_ru
                    
                    items {
                        title
                        title_en
                        title_ru
                        price
                        details
                        details_en
                        details_ru
                        image
                    }
                }
            }
        }
    }
`;
