import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import Image from './Image';

export default function MenuItemThumbnail({ filename, alt, ...props }) {
  const data = useStaticQuery(graphql`
        query {
            allImageSharp {
                edges {
                    node {
                        gatsbyImageData(
                            height: 125
                            placeholder: BLURRED
                        )
                        
                        parent {
                            ... on File {
                                relativePath
                            }
                        }
                    }
                }
            }
        }
    `);

  return <Image alt={alt} filename={filename} allImages={data.allImageSharp.edges} {...props} />;
}
